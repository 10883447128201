import {
  Configuration,
  ConfigurationParameters,
  DAADVERTISERCAMPAIGNSApi,
  USERApi,
  MISCApi,
  DOOHADVERTISERCAMPAIGNSApi,
  RETAILERADMINApi,
} from '../models/core'
import firebaseAuth from './firebaseAuth'

const BE_HOST = process.env.REACT_APP_BE_HOST || 'http://localhost'
const BE_PORT = process.env.REACT_APP_BE_PORT || '2000'

const defaultBasePath = `${BE_HOST}:${BE_PORT}`

export const coreAPIV3 = {
  misc: (configParams?: ConfigurationParameters) =>
    new MISCApi(
      new Configuration({
        basePath: defaultBasePath,
        ...configParams,
      })
    ),
  users: (configParams?: ConfigurationParameters) => {
    return new USERApi(
      new Configuration({
        accessToken: firebaseAuth.getIdToken(),
        ...configParams,
        basePath: defaultBasePath,
      })
    )
  },
  daAdvertiserCampaigns: (configParams?: ConfigurationParameters) =>
    new DAADVERTISERCAMPAIGNSApi(
      new Configuration({
        accessToken: firebaseAuth.getIdToken(),
        basePath: defaultBasePath,
        ...configParams,
      })
    ),
  doohAdvertiserCampaigns: (configParams?: ConfigurationParameters) =>
    new DOOHADVERTISERCAMPAIGNSApi(
      new Configuration({
        accessToken: firebaseAuth.getIdToken(),
        basePath: defaultBasePath,
        ...configParams,
      })
    ),
  retailerAdmin: (configParams?: ConfigurationParameters) =>
    new RETAILERADMINApi(
      new Configuration({
        accessToken: firebaseAuth.getIdToken(),
        basePath: defaultBasePath,
        ...configParams,
      })
    ),
}
